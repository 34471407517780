import { normalizeTel } from '@amo/core/utils/normalize.js';
import _ from 'lodash';
import moment from 'moment';
import {
    getApporteur1Init,
    getApporteur2Init,
    getAssure3DerniersMoisVehiculeDemandeTarifInit,
    getBirthCityPersonnePrincipaleDemandeTarifInit,
    getBirthDepartmentPersonnePrincipaleDemandeTarifInit,
    getBirthCityTuteurDemandeTarifInit,
    getBirthCountryPersonnePrincipaleDemandeTarifInit,
    getBirthCountryTuteurDemandeTarifInit,
    getBirthNamePersonnePrincipaleDemandeTarifInit,
    getBirthNameTuteurDemandeTarifInit,
    getCanalDemandeTarifInit,
    getCarteGriseFrancaiseVehiculeDemandeTarifInit,
    getCivilitePersonneSocieteTarif,
    getCivilitePersonnePrincipaleDemandeTarifInit,
    getCiviliteTuteurDemandeTarifInit,
    getCodePostalGarageVehiculeDemandeTarifInit,
    getCodePromoDemandeTarifInit,
    getConducteurMineurPersonnePrincipaleDemandeTarifInit,
    getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getDateNaissancePersonnePrincipaleDemandeTarifInit,
    getDateNaissanceTuteurDemandeTarifInit,
    getDevoirASS2DevoirConseilDemandeTarifInit,
    getDevoirASS3DevoirConseilDemandeTarifInit,
    getDevoirAccEqpDevoirConseilDemandeTarifInit,
    getDevoirDommageDevoirConseilDemandeTarifInit,
    getDevoirICDevoirConseilDemandeTarifInit,
    getDevoirMontantAccEqpDevoirConseilDemandeTarifInit,
    getDevoirRachatDevoirConseilDemandeTarifInit,
    getDevoirVolDevoirConseilDemandeTarifInit,
    getEmailSouscripteurTarif,
    getFormuleChoisiePoliceDemandeTarifInit,
    getFractionnementPoliceDemandeTarifInit,
    getGPDemandeTarifInit,
    getIdSocietyPersonneSocieteTarif,
    getIdentifiantSRAVehiculeDemandeTarifInit,
    getImmatriculationVehiculeDemandeTarifInit,
    getLegalStatusPersonneSocieteTarif,
    getListePermisPersonnePrincipaleDemandeTarifInit,
    getListeSinistresAntecedentsDemandeTarifInit,
    getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit,
    getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit,
    getNomPersonneSocieteTarif,
    getNomPersonnePrincipaleDemandeTarifInit,
    getNomTitulaireDemandeContratInit,
    getNomTuteurDemandeTarifInit,
    getNumPermisSouscripteurTarif,
    getOptionsDemandeTarifInit,
    getOptionsFranchiseDemandeTarifInit,
    getOptionsListeDemandeTarifInit,
    getPermisA1PersonnePrincipaleDemandeTarifInit,
    getPermisA2PersonnePrincipaleDemandeTarifInit,
    getPermisAMPersonnePrincipaleDemandeTarifInit,
    getPermisAPersonnePrincipaleDemandeTarifInit,
    getPermisBPersonnePrincipaleDemandeTarifInit,
    getPersonneSocieteDemandeTarifInit,
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit,
    getPrenomPersonnePrincipaleDemandeTarifInit,
    getPrenomTuteurDemandeTarifInit,
    getSignatureEmailDemandeContratInit,
    getState,
    getTelPortableSouscripteurTarif,
    getTiersPayeurBirthCityDemandeContratInit,
    getTiersPayeurBirthCountryDemandeContratInit,
    getTiersPayeurBirthDateDemandeContratInit,
    getTiersPayeurBirthNameDemandeContratInit,
    getTiersPayeurDemandeContratInit,
    getTiersPayeurFirstNameDemandeContratInit,
    getTiersPayeurLastNameDemandeContratInit,
    getTiersPayeurRelationshipDemandeContratInit,
    getTiersPayeurThirdPayerReasonDemandeContratInit,
    getTiersPayeurTypeDemandeContratInit,
    getTuteurDemandeTarifInit,
    getUsageNonLivraisonVehiculeDemandeTarifInit,
    getUsageVehiculeDemandeTarif,
    getValeurCrmAutoPersonnePrincipaleDemandeTarifInit,
    getValeurCrmMotoPersonnePrincipaleDemandeTarifInit,
    getVehiculeImmatriculeVehiculeDemandeTarifInit,
    getVilleGarageVehiculeDemandeTarifInit,
    getTelFixeSouscripteurTarif,
    getNumeroClientSouscripteurDemandeTarifInit,
    getSuspensionPermisAntecedentsDemandeTarifInit,
    getDateSuspensionPermisAntecedentsDemandeTarifInit,
    getNbMoisSuspensionPermisAntecedentsDemandeTarifInit,
    getDateAnnulationPermisAntecedentsDemandeTarifInit,
    getCondamnationAlcoolemieAntecedentsDemandeTarifInit,
    getTxAlcoolAntecedentsDemandeTarifInit,
    getResiliationAssureurAntecedentsDemandeTarifInit,
    getMotifResiliationAssureurAntecedentsDemandeTarifInit,
    getDateResiliationAssureurAntecedentsDemandeTarifInit,
    getDateAffaireNouvelleContratDemandeTarifInit,
    getDateEffetContratDemandeTarifInit,
    getDateProchaineEcheanceContratDemandeTarifInit,
    getModePaiementTermeDemandeContratInit,
    getNbMoisRefMotoDemandeTarifInit,
    getNbMoisRefAutoDemandeTarifInit,
    getListeContratsBelairInit,
    getDateAchatVehiculeDemandeTarif,
    getRealisateurDemandeTarifInit,
    getFidDemandeTarifInit,
    getRegulDemandeTarifInit, getMotifAvenantDemandeTarifInit
} from '../../../redux/selectors/init/init.selectors';
import {
    getCylindreeVehicule,
    getMarqueVehicule,
    getSraVehicule,
    getTypeVehicule,
} from '../../../redux/selectors/vehicule/vehicule.selectors';
import {
    getAdresse1SouscripteurDemandeTarifInit,
    getAdresse2SouscripteurDemandeTarifInit,
    getAdresse3SouscripteurDemandeTarifInit,
    getCPSouscripteurDemandeTarifInit,
    getPossessionPlusDe3MoisVehiculeDemandeTarifInit,
    getVilleSouscripteurDemandeTarifInit
} from "../../../redux/selectors/init/init.selectors.js";

export const InitialValues = (state) => {
    const isDevisOrDevisOrange = getState(state) === 'devis' || getState(state) === 'devis_orange';

    return {
        redirection: false,
        searchBy: getTypeVehicule(state) ? 'immatriculation' : null,
        typeVehicule: getTypeVehicule(state),
        marqueVehicule: getMarqueVehicule(state),
        modeleVehicule: getSraVehicule(state),
        cylindreeVehicule: getCylindreeVehicule(state) && String(getCylindreeVehicule(state)),
        options: getOptionsDemandeTarifInit(state),
        optionsFranchise: getOptionsFranchiseDemandeTarifInit(state),
        optionsListe: getOptionsListeDemandeTarifInit(state),
        /* Vérifier avec les selectors getAssureMotoInit ? */
        assure_moto: isDevisOrDevisOrange
            ? getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state)
                ? '1'
                : '0'
            : undefined,
        assure_auto: isDevisOrDevisOrange
            ? getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state)
                ? '1'
                : '0'
            : undefined,
        /* Déduction de la valeur entre societe ou a son nom, impossible entre nom de l'enfant ou a votre nom */
        titulaireCarteGrise:
            getPersonneSocieteDemandeTarifInit(state) && getCivilitePersonneSocieteTarif(state)
                ? '2'
                : getNomPersonnePrincipaleDemandeTarifInit(state) || getPrenomPersonnePrincipaleDemandeTarifInit(state)
                    ? '1'
                    : undefined,
        sinistre_auto: isDevisOrDevisOrange
            ? getListeSinistresAntecedentsDemandeTarifInit(state) &&
            getListeSinistresAntecedentsDemandeTarifInit(state).length > 0
                ? '1'
                : '0'
            : undefined,
        DemandeDevis: {
        },
        DemandeTarif: {
            GoodbyePack: getGPDemandeTarifInit(state),
            CodePromo: {
                CodePromo: getCodePromoDemandeTarifInit(state),
            },
            ListeContratsBelair: getListeContratsBelairInit(state),
            Police: {
                Canal: getCanalDemandeTarifInit(state),
                FractionnementChoisi: getFractionnementPoliceDemandeTarifInit(state)
                    ? getFractionnementPoliceDemandeTarifInit(state) === 'S'
                        ? 'A'
                        : getFractionnementPoliceDemandeTarifInit(state)
                    : 'M',
                FormuleChoisie: getFormuleChoisiePoliceDemandeTarifInit(state),
                IdApporteur1: getApporteur1Init(state)?.codeBelair,
                IdApporteur2: getApporteur2Init(state)?.codeBelair,
                DateAffaireNouvelleContrat: getDateAffaireNouvelleContratDemandeTarifInit(state),
                DateEffetContrat: getDateEffetContratDemandeTarifInit(state),
                DateProchaineEcheanceContrat: getDateProchaineEcheanceContratDemandeTarifInit(state),
                IdRealisateur: getRealisateurDemandeTarifInit(state),
                MotifAvenant: getFidDemandeTarifInit(state) ? 29 : getRegulDemandeTarifInit(state) ? 7 : getMotifAvenantDemandeTarifInit(state)
            },
            ListePersonnes: [
                {
                    TypePersonne: 'P',
                    RoleConducteur: 'P',
                    Souscripteur: getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state) === 'true',
                    DateNaissance: getDateNaissancePersonnePrincipaleDemandeTarifInit(state) && moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY'),
                    ListePermis:
                        getListePermisPersonnePrincipaleDemandeTarifInit(state).length > 0
                            ? [
                                {
                                    TypePermis: getPermisAMPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                    DatePermis: getPermisAMPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                },
                                {
                                    TypePermis: getPermisA1PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                    DatePermis: getPermisA1PersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                },
                                {
                                    TypePermis: getPermisA2PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                    DatePermis: getPermisA2PersonnePrincipaleDemandeTarifInit(state).DatePermis ,
                                },
                                {
                                    TypePermis: getPermisAPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                    DatePermis: getPermisAPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                },
                                {
                                    TypePermis: getPermisBPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                    DatePermis: getPermisBPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                },
                            ]
                            : null,
                    Civilite: getCivilitePersonnePrincipaleDemandeTarifInit(state),
                    Nom: getNomPersonnePrincipaleDemandeTarifInit(state),
                    DejaClient: '0', //getIsDejaClientDemandeTarifInit(state),
                    Prenom: getPrenomPersonnePrincipaleDemandeTarifInit(state),
                    TelPortable: getTelPortableSouscripteurTarif(state) ? normalizeTel(getTelPortableSouscripteurTarif(state)) : normalizeTel(getTelFixeSouscripteurTarif(state)),
                    NumPermis: getNumPermisSouscripteurTarif(state),
                    Email: getEmailSouscripteurTarif(state),
                    ValeurCrmMoto: getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state)
                        ? String(Number(getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                        : null,
                    CrmMoto50Plus3Ans: getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                    ValeurCrmAuto: getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state)
                        ? String(Number(getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                        : null,
                    CrmAuto50Plus3Ans: getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                    NbMoisAssuranceMoto: getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state)
                        ? Number(getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                        : null,
                    NbMoisAssuranceAuto: getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state)
                        ? Number(getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                        : null,
                    BirthCountry: getBirthCountryPersonnePrincipaleDemandeTarifInit(state),
                    BirthCity: getBirthCityPersonnePrincipaleDemandeTarifInit(state),
                    BirthDepartment: getBirthDepartmentPersonnePrincipaleDemandeTarifInit(state),
                    BirthName: getBirthNamePersonnePrincipaleDemandeTarifInit(state),
                    ConducteurMineur: getConducteurMineurPersonnePrincipaleDemandeTarifInit(state) ?? false,
                    NumeroClient: getNumeroClientSouscripteurDemandeTarifInit(state),

                    Adresse1: getAdresse1SouscripteurDemandeTarifInit(state),
                    Adresse2: getAdresse2SouscripteurDemandeTarifInit(state),
                    Adresse3: getAdresse3SouscripteurDemandeTarifInit(state),
                    Ville: getVilleSouscripteurDemandeTarifInit(state),
                    CP: getCPSouscripteurDemandeTarifInit(state),
                    NbMoisReferenceMoto: String(getNbMoisRefMotoDemandeTarifInit(state)),
                    NbMoisReferenceAuto: String(getNbMoisRefAutoDemandeTarifInit(state)),
                },
                (
                    !_.isEmpty(getTuteurDemandeTarifInit(state)) ||
                    moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY').add(18, 'years') >
                    moment()
                )
                    ? {
                        Souscripteur: true,
                        TypePersonne: 'P',
                        RoleConducteur: 'N',
                        DateNaissance:
                            getDateNaissanceTuteurDemandeTarifInit(state) &&
                            moment(getDateNaissanceTuteurDemandeTarifInit(state), 'DD/MM/YYYY'),
                        Civilite: getCiviliteTuteurDemandeTarifInit(state),
                        Nom: getNomTuteurDemandeTarifInit(state),
                        Prenom: getPrenomTuteurDemandeTarifInit(state),
                        BirthCountry: getBirthCountryTuteurDemandeTarifInit(state),
                        BirthCity: getBirthCityTuteurDemandeTarifInit(state),
                        BirthName: getBirthNameTuteurDemandeTarifInit(state),
                        NumeroClient: getNumeroClientSouscripteurDemandeTarifInit(state),
                        Adresse1: getAdresse1SouscripteurDemandeTarifInit(state),
                        Adresse2: getAdresse2SouscripteurDemandeTarifInit(state),
                        Adresse3: getAdresse3SouscripteurDemandeTarifInit(state),
                        Ville: getVilleSouscripteurDemandeTarifInit(state),
                        CP: getCPSouscripteurDemandeTarifInit(state),
                    }
                    : !_.isEmpty(getPersonneSocieteDemandeTarifInit(state))
                        ? {
                            Souscripteur: true,
                            TypePersonne: 'M',
                            RoleConducteur: 'N',
                            Civilite: getCivilitePersonneSocieteTarif(state),
                            Nom: getNomPersonneSocieteTarif(state),
                            LegalStatus: getLegalStatusPersonneSocieteTarif(state),
                            IdSociety: getIdSocietyPersonneSocieteTarif(state),
                            NumeroClient: getNumeroClientSouscripteurDemandeTarifInit(state),
                            Adresse1: getAdresse1SouscripteurDemandeTarifInit(state),
                            Adresse2: getAdresse2SouscripteurDemandeTarifInit(state),
                            Adresse3: getAdresse3SouscripteurDemandeTarifInit(state),
                            Ville: getVilleSouscripteurDemandeTarifInit(state),
                            CP: getCPSouscripteurDemandeTarifInit(state),
                        }
                        : undefined,
            ],
            Vehicule: {
                /* Force à non en cas de reprise pour poser la question (champs non retournés dans le devis-ws) */
                possessionPlusDe3Mois: _.includes(['true', '1', true], getPossessionPlusDe3MoisVehiculeDemandeTarifInit(state)) ? '1' : undefined,
                VehiculeImmatricule: getImmatriculationVehiculeDemandeTarifInit(state)
                    ? '1'
                    : getVehiculeImmatriculeVehiculeDemandeTarifInit(state)
                        ? getVehiculeImmatriculeVehiculeDemandeTarifInit(state)
                        :  undefined,
                Immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
                SituationVehicule: '1',
                FamilleProduit: 'Moto',
                IdentifiantSRA: getIdentifiantSRAVehiculeDemandeTarifInit(state),
                Assure3DerniersMois: _.includes(
                    ['true', '1', true],
                    getAssure3DerniersMoisVehiculeDemandeTarifInit(state),
                )
                    ? '1'
                    : _.includes(['false', '0', false], getAssure3DerniersMoisVehiculeDemandeTarifInit(state))
                        ? '0'
                        : undefined,
                UsageVehicule: getUsageVehiculeDemandeTarif(state),
                UsageNonLivraison: getUsageNonLivraisonVehiculeDemandeTarifInit(state),
                CarteGriseFrancaise: getCarteGriseFrancaiseVehiculeDemandeTarifInit(state) && '1',
                VilleGarage: getVilleGarageVehiculeDemandeTarifInit(state),
                CodePostalGarage: getCodePostalGarageVehiculeDemandeTarifInit(state),
                TitulaireCarteGrise: true,
                DateAchatVehicule: moment(getDateAchatVehiculeDemandeTarif(state), 'DD/MM/YYYY')
            },
            Antecedents: {
                DateSuspensionPermis: (
                        getSuspensionPermisAntecedentsDemandeTarifInit(state) === 'true' ||
                        getSuspensionPermisAntecedentsDemandeTarifInit(state) === '1'
                    ) && getDateSuspensionPermisAntecedentsDemandeTarifInit(state)
                        ? moment(getDateSuspensionPermisAntecedentsDemandeTarifInit(state), 'DD/MM/YYYY')
                        : undefined,
                NbMoisSuspensionPermis: (
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === 'true' ||
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === '1'
                )
                    ? getNbMoisSuspensionPermisAntecedentsDemandeTarifInit(state)
                    : undefined,
                DateAnnulationPermis: (
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === 'true' ||
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === '1'
                ) && getDateAnnulationPermisAntecedentsDemandeTarifInit(state)
                    ? moment(getDateAnnulationPermisAntecedentsDemandeTarifInit(state), 'DD/MM/YYYY')
                    : undefined,
                TxAlcool: (
                    getCondamnationAlcoolemieAntecedentsDemandeTarifInit(state) === 'true' ||
                    getCondamnationAlcoolemieAntecedentsDemandeTarifInit(state) === '1'
                )
                    ? getTxAlcoolAntecedentsDemandeTarifInit(state)
                    : undefined,
                DateResiliationAssureur: (
                    getResiliationAssureurAntecedentsDemandeTarifInit(state) === 'true' ||
                    getResiliationAssureurAntecedentsDemandeTarifInit(state) === '1'
                ) && getDateResiliationAssureurAntecedentsDemandeTarifInit(state)
                    ? moment(getDateResiliationAssureurAntecedentsDemandeTarifInit(state), 'DD/MM/YYYY')
                    : undefined,
                MotifResiliationAssureur: (
                    getResiliationAssureurAntecedentsDemandeTarifInit(state) === 'true' ||
                    getResiliationAssureurAntecedentsDemandeTarifInit(state) === '1'
                )
                    ? getMotifResiliationAssureurAntecedentsDemandeTarifInit(state)
                    : undefined,
                CondamnationConduiteSansAssurance: '0',
                ListeSinistres: getListeSinistresAntecedentsDemandeTarifInit(state),
            },
            DevoirConseil: {
                DevoirVol:
                    getDevoirVolDevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirVolDevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirVolDevoirConseilDemandeTarifInit(state) === 'true' ||
                        getDevoirVolDevoirConseilDemandeTarifInit(state) === '1'
                            ? '1'
                            : '',
                DevoirDommage:
                    getDevoirDommageDevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirDommageDevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirDommageDevoirConseilDemandeTarifInit(state) === 'true' ||
                        getDevoirDommageDevoirConseilDemandeTarifInit(state) === '1'
                            ? '1'
                            : '',
                DevoirASS2:
                    getDevoirASS2DevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirASS2DevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirASS2DevoirConseilDemandeTarifInit(state) === 'true' ||
                        getDevoirASS2DevoirConseilDemandeTarifInit(state) === '1'
                            ? '1'
                            : '',
                DevoirASS3: getDevoirASS3DevoirConseilDemandeTarifInit(state),
                DevoirIC: getDevoirICDevoirConseilDemandeTarifInit(state),
                DevoirACCESSEQP: getDevoirAccEqpDevoirConseilDemandeTarifInit(state),
                DevoirMontantACCESSEQP: getDevoirMontantAccEqpDevoirConseilDemandeTarifInit(state),
                DevoirRAFRA: getDevoirRachatDevoirConseilDemandeTarifInit(state),
            },
        },
        DemandeContrat: {
            ModePaiementTerme: getModePaiementTermeDemandeContratInit(state),
            ModePaiementComptant: 'C',
            Signature: true,
            SignatureEmail: getSignatureEmailDemandeContratInit(state) || getEmailSouscripteurTarif(state),
            NomTitulaire:
                getNomTitulaireDemandeContratInit(state) != 'undefined undefined'
                    ? getNomTitulaireDemandeContratInit(state)
                    : `${getNomPersonnePrincipaleDemandeTarifInit(state)} ${getPrenomPersonnePrincipaleDemandeTarifInit(
                        state,
                    )}`,
            DateHeureEffet: moment().add(30, 'minutes').format('DD/MM/YYYY HH:mm'),
            TiersPayeur: !_.isEmpty(getTiersPayeurDemandeContratInit(state))
                ? {
                    Type: getTiersPayeurTypeDemandeContratInit(state) || 'P',
                    LastName: getTiersPayeurLastNameDemandeContratInit(state),
                    BirthName: getTiersPayeurBirthNameDemandeContratInit(state),
                    FirstName: getTiersPayeurFirstNameDemandeContratInit(state),
                    BirthDate: getTiersPayeurBirthDateDemandeContratInit(state),
                    BirthCountry: getTiersPayeurBirthCountryDemandeContratInit(state),
                    BirthCity: getTiersPayeurBirthCityDemandeContratInit(state),
                    Relationship: getTiersPayeurRelationshipDemandeContratInit(state),
                    ThirdPayerReason: getTiersPayeurThirdPayerReasonDemandeContratInit(state),
                }
                : {},
        },
    };
};
