/*

TODO DETRUIRE ET UTILISER CAPADRESSE LORSQUE LES FORMULAIRES AURONT LA MÊME STRUCTURE

 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {getAddress} from "../../../utils/index.js";
import _ from "lodash";
import {Text} from "../Text/Text.jsx";
import {Checkbox} from "../Checkbox/Checkbox.jsx";
import {Field} from "redux-form";
import {QuestionLabel} from "../../containers/index.js";
import { required } from '@amo/core/utils/validateField';

export const CAPAdresseTrottinetteVae = (props) => {
    const { setHasSearchAddress, data, changeValue, font = 16, label, noLabel, disabled, path = 'DemandeTarif[ListePersonnes][0]' } = props
    const isFirstRender = useRef(true);

    const [listAddresses, setListAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState(_.get(data, `${path}[Adresse1]`) || null);
    const [localityId, setLocalityId] = useState(null);

    const ville = useMemo(() => _.get(data, `${path}[Ville]`), [data]);
    const codePostal = useMemo(() => _.get(data, `${path}[CP]`), [data]);

    useEffect(() => {
        if (ville && codePostal) {
            setAddressSelected(ville + ' ' + codePostal);
            setHasSearchAddress(true);
        }
    }, [ville, codePostal]);

    useEffect(() => {
        if (isFirstRender.current) {
            // Si c'est le premier rendu, on met la référence à false et on quitte le useEffect
            isFirstRender.current = false;
            return;
        }
        if(_.get(data, `${path}[Ville]`) && _.get(data, `${path}[CP]`)){
            fetchAddress(`${_.get(data, `${path}[Ville]`)} ${_.get(data, `${path}[CP]`)}`, 'city')
        }
    }, [_.get(data, `${path}[Ville]`), _.get(data, `${path}[CP]`)]);

    const fetchAddress = async (value, where) => {
        setAddressSelected(null);
        let addressParams;
        if (where === 'city') {
            addressParams = [value, 'Undefined'];
        } else {
            addressParams = [
                value,
                'Undefined',
                localityId,
                _.get(data, `${path}[CP]`),
                _.get(data, `${path}[Ville]`)
            ];
        }
        let address = await getAddress(...addressParams);

        //SERVICES en panne
        if(address.status === 500){
            changeValue('addressNotFound', true);
            const element = document.getElementById('addressNotFound');
            if (element) {
                element.style.display = 'none';
            }
        } else {
            if (where === 'city') {
                setLocalityId(address[0].localityId);
                setListAddresses([])
            } else {
                setListAddresses(address?.slice(0, 15));
            }
            setHasSearchAddress(true);
        }
    };

    const selectAddr = (address) => {
        setAddressSelected(address);
        changeValue(`${path}[Adresse1]`, address.additionalInfo_1);
        changeValue(`${path}[Adresse2]`, '');
        changeValue(`${path}[Adresse3]`, address.buildingName || '');
    };

    const validateAddress = useCallback(
        () => (addressSelected || _.get(data, 'addressNotFound') ? undefined : "Veuillez sélectionner une adresse dans la liste"),
        [addressSelected, _.get(data, 'addressNotFound')]
    );

    return (
        <>
        { !noLabel && <QuestionLabel className={`f-${font} mt-4`}>{label?.adresse1 ? label?.adresse1 : "Adresse"}<sup className={'text-danger'}>*</sup></QuestionLabel>}
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-4 mb-2'}>
                    <Field
                        name={`${path}[Adresse1]`}
                        component={Text}
                        type={'text'}
                        placeholder={noLabel ? 'Adresse' : 'Exemple : ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS'}
                        onChange={(e) =>
                            e.target.value.length > 5 && !_.get(data, 'addressNotFound')
                                ? fetchAddress(e.target.value, 'address')
                                : null
                        }
                        validate={[required, validateAddress]}
                        disabled={disabled}
                    />
                    {!_.get(data, 'addressNotFound') &&
                        !addressSelected &&
                        _.map(listAddresses, (adresse, i) => (
                            <div key={i} onClick={() => selectAddr(adresse)} className={'list-group'}>
                                <p className={'text-start cursor-pointer m-0 list-group-item list-group-item-action'}>
                                    {adresse.inputOutput}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div id={'addressNotFound'} >
                <Field className={'mt-2 ml-2'} name="addressNotFound" component={Checkbox} disabled={disabled}>
                    <p className={'mt-1 ml-2'}>Je ne trouve pas mon adresse</p>
                </Field>
            </div>

            {(_.get(data, `${path}[Adresse1]`) || addressSelected || _.get(data, 'addressNotFound')) && (
                <>
                { !noLabel && <QuestionLabel className={`f-${font} mt-4`}>{label?.adresse2 ? label?.adresse2 : 'Avez-vous un complément d\'adresse'}&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel> }
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                            <Field
                                component={Text}
                                placeholder={noLabel && 'Avez-vous un complément d\'adresse'}
                                type={'text'}
                                name={`${path}[Adresse2]`}
                                disabled={(addressSelected && !_.get(data, 'addressNotFound')) || disabled}
                            />
                        </div>
                    </div>
                { !noLabel && <QuestionLabel className={`f-${font} mt-4`}>{label?.adresse3 ? label?.adresse3 : 'Bâtiment, escalier, étages'}<sup className={'text-danger'}>*</sup></QuestionLabel> }
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                            <Field
                                component={Text}
                                placeholder={noLabel && 'Bâtiment, escalier, étages'}
                                type={'text'}
                                name={`${path}[Adresse3]`}
                                normalize={(v) => v.toUpperCase()}
                                disabled={(addressSelected && !_.get(data, 'addressNotFound')) || disabled}
                            />
                    </div>
                    </div>
                </>
            )}
        </>
    );
};