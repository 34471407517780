import ChoixPermis from "./ChoixPermis/ChoixPermis.jsx";
import {DateNaissance, DateNaissanceRepresentantLegal} from "@amo/core/components/questions";
import AdresseActuelleContainer from "../AdresseActuelle/AdresseActuelleContainer.js";
import moment from "moment";
import _ from "lodash";
import NouvelleAdresse from "../ChangementAdresse/NouvelleAdresse/NouvelleAdresse.jsx";
import IdentiqueStationnement from "../ChangementAdresse/IdentiqueStationnement/IdentiqueStationnement.jsx";
import LieuStationnement from "../ChangementAdresse/LieuStationnement/LieuStationnement.jsx";
import {useEffect} from "react";

const ChangementConducteur = (props) => {
    const {id, data, changeValue} = props;
    const conducteur = _.get(data, 'DemandeTarif[ListePersonnes][0]')
    const souscripteurIsMineur = (data) => {
        return (
            moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') >=
            moment()
        );
    };

    const souscripteurIsSoc = (data) => {
        return (
            _.get(data, 'DemandeTarif[ListePersonnes][1][Souscripteur]') && _.get(data, 'DemandeTarif[ListePersonnes][1][TypePersonne]') === 'M'
        );
    };

    useEffect(() => {
        if(souscripteurIsMineur(data)) {
            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P')
            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true)

            changeValue('DemandeTarif[ListePersonnes][0][TypePersonne]', 'P')
            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false)
        } else if(!_.isEmpty(conducteur) && !souscripteurIsSoc(data)) {
            changeValue('DemandeTarif[ListePersonnes]', [{...conducteur}])
            // ajout pour correction lorsque le conducteur est remplacer par le majeur
            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true)
            changeValue('DemandeTarif[ListePersonnes][0][ConducteurMineur]', false)
        }
    }, [_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]')]);

    return (
        <div id={id}>
            <DateNaissance {...props} />
            {souscripteurIsMineur(data) && <DateNaissanceRepresentantLegal {...props} />}
            <ChoixPermis {...props} />
            <AdresseActuelleContainer {...props} />
            {data?.changeAdresse === '0' && <NouvelleAdresse {...props} />}
            <IdentiqueStationnement {...props} />
            {_.get(data, 'sameStationnement') === '0' && <LieuStationnement {...props} />}
        </div>
    );
};

export default ChangementConducteur;
