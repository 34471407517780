import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './autocomplete.scss';

const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const getSuggestionValue = (suggestion) => {
    return `${suggestion.label}`;
};

const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.label}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
        <span className={'suggestion-content ' + suggestion.twitter}>
            {parts.map((part, index) => {
                const className = part.highlight ? 'highlight' : null;
                return (
                    <span className={className} key={index}>
                        {part.text}
                    </span>
                );
            })}
        </span>
    );
};

export const Autocomplete = ({
    data = [],
    input,
    defaultValue = '',
    placeholder = '',
    label = '',
    required = false,
    setQuery,
    afterValidate,
    disabled = false
}) => {
    const [value, setValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {

        setSuggestions(getSuggestions(value));
        const item = _.find(suggestions, ['label', value]);
        if (item) {
            input.onChange(item.value);
            afterValidate && afterValidate(item.value);
        }
    }, [data]);

    const getSuggestions = (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('\\b' + escapedValue, 'i');
        return _.slice(
            data.filter((item) => regex.test(getSuggestionValue(item))),
            0,
            10,
        );
    };

    const onChange = (event, { newValue, method }) => {
        setValue(newValue);
        if (setQuery) {
            setQuery(newValue);
        }
        const item = _.find(suggestions, ['label', newValue]);
        if (item) {
            input.onChange(item.value);
            afterValidate && afterValidate(item.value);
        }
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder,
        value,
        onChange,
        disabled,
    };

    return (
        <div className={'form-group'} data-name={input.name}>
            {label && (
                <label className={'label-text mb-1'}>
                    {label} {label && required ? <sup className={'text-danger'}>*</sup> : ''}
                </label>
            )}
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        </div>
    );
};

Autocomplete.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    setQuery: PropTypes.func,
    afterValidate: PropTypes.func,
};
