import motards from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import cross from '@amo/core/assets/images/produits/icon_cross.svg';
import moto from '@amo/core/assets/images/produits/icon_moto.svg';
import quad from '@amo/core/assets/images/produits/icon_quad.svg';
import scooter from '@amo/core/assets/images/produits/icon_scooter.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const TypeVehicule = (props) => {
    const { nextQuestion, data, changeValue, apporteur1 } = props;

    const selectTypeVehicule = (typeVehicule) => {
        switch (typeVehicule) {
            case 'moto':
            case 'scooter':
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                changeValue('DemandeTarif[Vehicule][DateMEC]', null)
                break;
            case '50':
                changeValue('cylindreeVehicule', '50');
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                changeValue('DemandeTarif[Vehicule][DateMEC]', null)
                break;
            case 'quad':
            case 'verte':
                changeValue('DemandeTarif[Vehicule][UsageVehicule]', '2');
                changeValue('DemandeTarif[Vehicule][DateMEC]', null)
                break;
            default:
                break;
        }

        changeValue('typeVehicule', typeVehicule);
        changeValue('searchBy', 'immatriculation');
     /*   nextQuestion(300);*/
    };

    return (
        <Question icon={motards} title={'Quel <b>type de véhicule</b> <br/>souhaitez-vous <b>assurer</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'typeVehicule'}
                        value={'moto'}
                        label={'Moto'}
                        onChange={(e) => {
                            selectTypeVehicule(e.target.value);
                        }}
                        icon={moto}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'typeVehicule'}
                        value={'scooter'}
                        label={'Scooter'}
                        onChange={(e) => {
                            selectTypeVehicule(e.target.value);
                        }}
                        icon={scooter}
                    />
                </div>
                {apporteur1.codeBelair !== 809466 && (
                    <>
                        <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'typeVehicule'}
                                value={'50'}
                                label={'Cyclo 50cm3'}
                                onChange={(e) => {
                                    selectTypeVehicule(e.target.value);
                                }}
                                icon={scooter}
                            />
                        </div>
                        <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'typeVehicule'}
                                value={'quad'}
                                label={'Quad'}
                                onChange={(e) => {
                                    selectTypeVehicule(e.target.value);
                                }}
                                icon={quad}
                            />
                        </div>
                        <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'typeVehicule'}
                                value={'verte'}
                                label={'Moto verte'}
                                onChange={(e) => {
                                    selectTypeVehicule(e.target.value);
                                }}
                                icon={cross}
                            />
                        </div>
                    </>
                )}
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!data?.typeVehicule && 'disabled'}`}
                onClick={() => nextQuestion(300)}
            >
                Continuer
            </button>
        </Question>
    );
};

export default TypeVehicule;
